import "./ProjectList.scss"

import React, { FC, useEffect, useState,useContext } from "react";
import NavContext from "../../components/Layout/Nav/NavProvider";
import Project from '../../components/Project';
import { FilterUrlConstants, getSectionSlug, YouplanetSections } from '../../constants/general';
import { ProjectType } from '../../models/project.model';
import ArrowMoreContent from '../../components/ArrowMoreContent';
import { useCustomHistory } from '../../hooks/useCustomHistory';
import { getCurrentLanguage } from '../../i18n';
export interface ProjectListProps {
  projects: any[];
  filterIndex: { [key: string]: string[]; };
  disableScroll?: boolean;
}

const ProjectList: FC<ProjectListProps> = ({ projects, filterIndex, disableScroll }) => {
  const [projectItems, setProjectItems] = useState<ProjectType[]>([]);
  const [ownerUrlParams, setOwnerUrlParams] = useState<string>();
  const [showGoBackArrow, setShowGoBackArrow] = useState<boolean>();
  const { filters, isMobile } = useContext(NavContext);
  const { goBack, replaceHistoryWithPathName } = useCustomHistory();

  const formatOrder = (index: number) => {
    return index.toString().padStart(2, '0');
  };

  const getDefaultProjects = () => {
    const params = new URLSearchParams(location.search);
    const urlTalentOwnerParam = params.get(FilterUrlConstants.TalentOwnerName);
    if (!urlTalentOwnerParam || ownerUrlParams) {
      return projects;
    }

    replaceHistoryWithPathName(isMobile());
    setShowGoBackArrow(true);
    const filteredProjects = projects.filter(p => p.talentOwners.some(t => t.name === urlTalentOwnerParam));
    setOwnerUrlParams(urlTalentOwnerParam);
    
    return filteredProjects
  }

  useEffect(() => {
    if (!filters.length) {
      setProjectItems(getDefaultProjects());
      return;
    }
    
    let filteredProjects = [];
    filters.forEach(filter => {
      const matchedProjects = filterIndex[filter] || [];
      filteredProjects = [...filteredProjects, ...matchedProjects];
    });
    setShowGoBackArrow(false);

    const filteredProjectsUniques = Array.from(new Set(filteredProjects));
    setProjectItems(filteredProjectsUniques);
  }, [projects, filters]);

  return (
    <div className={`project-list ${disableScroll ? 'no-scroll' : ''}`}>
      { showGoBackArrow &&
        <div className="project-list__link">
          <ArrowMoreContent
            containerHeight="2rem"
            containerWidth="16rem"
            sideWidth="1rem"
            width=".2rem"
            direction="left"
            clickHandler={() => goBack(getSectionSlug(YouplanetSections.TalentList, getCurrentLanguage()))}
            />
        </div>
      }
      <div className='project-list__content'> 
        <div className={`project-list__grid ${disableScroll ? '' : 'scrollable' }`}> 
          {projectItems.map((project, index) =>
            <div className="project-list__item" key={index}>
              <Project
                slug={`/${project.slug.current}`}
                title={project.name}
                subTitle={project.brand}
                videoId={project.youtubeId}
                order={formatOrder(index + 1)}
                headerAnimated
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectList
