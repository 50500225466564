import "normalize.css";
import "./ProjectListTemplate.scss";

import React, { FC, useContext, useEffect, useRef, useState } from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import NavContext, {
  NavProvider,
} from "../../components/Layout/Nav/NavProvider";

import HomeSliderContact from "../../components/HomeSliderContact";
import Button from "../../components/Button";
import Filters from "../../components/Filters";
import SectionHeader from "../../components/SectionHeader";
import Slider from "../../components/Slider";
import { graphql } from "gatsby";
import { t } from "i18next";
import { PageTemplateProps } from "../../constants/general";
import ProjectList from "../../components/ProjectList";
import { useInView } from "../../hooks/useInView";
import { getCurrentLanguage } from "../../i18n";

const PageTemplate: FC<PageTemplateProps> = ({ data, pageContext }) => {
  const currentSlug = pageContext.slug;
  const language = getCurrentLanguage();
  const projectList = data.project.nodes.filter(x => x.slug.current.includes(`${language}/`));

  const pageTitle = `${t("projects.pageTitle")} Youplanet`;
  const description = `${t("projects.pageTitle")} Youplanet`;
  const alternativeUrls = pageContext.alternativeUrls;
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);
  const { isMobile } = useContext(NavContext);

  const section = useRef<HTMLDivElement>();

  const inView = useInView(section, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  const buttonShowFilterClickHandler = () => {
    setOpenFilters(true);
  };

  const buttonApplyFilterClickHandler = () => {
    setOpenFilters(false);
  };

  const getCategories = (projects: any[]) => {
    const categories: { [key: string]: string } = {};

    projects.forEach((project) => {
      project.categories.forEach((category) => {
        categories[category.slug.current] = category.name;
      });
    });
    return categories;
  };

  const getFilterIndex = (projects: any[]) => {
    const index: { [key: string]: string[] } = {};

    projects.forEach((project) => {
      project.categories.forEach((category) => {
        if (!index[category.slug.current]) {
          index[category.slug.current] = [];
        }
        index[category.slug.current].push(project);
      });
    });
    return index;
  };

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  const filterOptions = getCategories(projectList);

  function pageContent() {
    const elements: JSX.Element[] = [
      <div className="projects__content">
        <div className={`projects__button-show-filter`}>
          <Button clickHandler={buttonShowFilterClickHandler} uppercase={true}>
            {t("filters.button")}
          </Button>
        </div>
        <div className={`projects__list`}>
          <ProjectList
            filterIndex={getFilterIndex(projectList)}
            projects={projectList}
          />
        </div>
      </div>,
      <HomeSliderContact />,
    ];
    return elements.map((el, index) => <span key={index}>{el}</span>);
  }

  return (
    <NavProvider>
      <Layout
        language={language}
        alternativeUrls={alternativeUrls}
        isHome={false}
      >
        <SEO
          title={pageTitle}
          description={description}
          lang={language}
          slug={currentSlug}
        />
        <SectionHeader h1={t("projects.h1")} inView={true} />
        <section
          ref={section}
          className={`projects ${inView ? "projects--in-view" : ""}`}
        >
          <Filters
            options={filterOptions}
            opened={openFilters}
            applyButtonHandler={buttonApplyFilterClickHandler}
          />
          {isMobileActive ? (
            pageContent()
          ) : (
            <Slider hidePagination>{pageContent()}</Slider>
          )}
        </section>
      </Layout>
    </NavProvider>
  );
};

export default PageTemplate;

export const query = graphql`
  query ProjectListTemplateQuery($lang: String) {
    project: allSanityProject(
      sort: { order: ASC, fields: [position] }
      filter: { i18n_lang: { in: [$lang, null] }, _id: { regex: "/^(?!drafts.).*/" } }
    ) {
      nodes {
        name
        brand
        youtubeId
        showInProject
        slug {
          current
        }
        categories: category {
          name
          slug {
            current
          }
        }
        talentOwners { 
          id
          name
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
